import React, { useContext } from 'react'
// import { makeStyles } from '@mui/styles'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme
} from '@mui/material'
import DownLoadIcon from '@mui/icons-material/CloudDownload'
import Button from '../CustomButtons/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'

// const useStyles = makeStyles(presentationStyle)

// Funzione che scarica il documento
const downloadDocument = (name, url) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  a.href = url
  a.download = name
  a.click()
  window.URL.revokeObjectURL(url)
}

const CertDialog = () => {
  const theme = useTheme()
  // const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isSmall = useMediaQuery('(max-width: 600px)')
  const [fullWidth] = React.useState(true)
  const [maxWidth] = React.useState('sm')

  const { state, dispatch } = useContext(AppContext)

  const { openCert, selectedCert } = state

  // funzione che chiude modal documenti
  const handleCloseCert = () => {
    dispatch({
      type: 'SET_OPEN_CERT',
      payload: false
    })
  }

  const { t } = useTranslation('certDialog')

  return (
    <Dialog
      fullScreen={fullScreen}
      scroll={'paper'}
      open={openCert}
      onClose={handleCloseCert}
      aria-labelledby='certification-dialog'
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle
        className='fredoka'
        id='certification-dialog'
      >{`${t('title')}. "${selectedCert.name}"`}</DialogTitle>
      <DialogContent
        dividers={true}
        style={{ paddingLeft: 8, paddingRight: 8 }}
      >
        {isSmall ? (
          <Table className='certTable' aria-label='Track documents'>
            <TableBody>
              <TableRow>
                <TableCell align='left'>
                  <b>{t('description')}</b>
                </TableCell>
                <TableCell align='left'>{selectedCert.description}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>
                  <b>{t('authority')}</b>
                </TableCell>
                <TableCell align='left'>{selectedCert.authority}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>
                  <b>{t('protocolNum')}</b>
                </TableCell>
                <TableCell align='left'>{selectedCert.num}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : null}
        <Box style={{ padding: '20px 0' }}>
          <Typography className='fredoka' component='span' variant='h6'>
            {t('tableTitle')}
          </Typography>
          <Typography
            className='raleway'
            style={{ marginTop: '8px', color: '#9e9e9e' }}
            component='h2'
            variant='body2'
          >
            {selectedCert.documents && selectedCert.documents.length > 0
              ? t('downloadMessage')
              : t('noDocsMessage')}
          </Typography>
        </Box>
        <List>
          {selectedCert.documents &&
            selectedCert.documents.map((document, docIndex) => (
              <ListItem
                style={{
                  margin: '16px 0',
                  backgroundColor: docIndex % 2 === 0 ? 'white' : '#fafafa',
                  padding: '0px'
                }}
                key={`doc-${docIndex}`}
              >
                <ListItemText
                  primary={
                    <div
                      style={
                        isSmall
                          ? presentationStyle.textEllipsisMobile
                          : presentationStyle.textEllipsis
                      }
                    >
                      {document.name || t('defaultName')}
                    </div>
                  }
                />
                <ListItemSecondaryAction>
                  <Button
                    classes={{
                      containedPrimary: presentationStyle.chipPrimary
                    }}
                    onClick={() =>
                      downloadDocument(document.name, document.fileUrl)
                    }
                    variant='contained'
                    color='ctaButton'
                    endIcon={<DownLoadIcon />}
                  >
                    {t('downloadButton')}
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCert} color='generalButton'>
          {t('closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CertDialog
