/* eslint-disable no-undef */
// require('dotenv').config()
const manifest = require('../package.json')
const configuration = {
  logTag: process.env.REACT_APP_APIO_LOG || null,
  version: manifest.version,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://api.trusty.id',
  publicApiKey: process.env.REACT_APP_PUBLIC_API_KEY,
  // NOTE: quando si creerà un ambiente di test anche per l'api di tracciabilità allora si deve mettere che di default l'endpoint è quello
  traceabilityApi: {
    uri:
      process.env.REACT_APP_TRACEABILITY_BASE_URL ||
      'https://traceabilityapi.trusty.id',
    versioning: process.env.REACT_APP_TRACEABILITY_VERSION || 'v1'
  },
  environment: process.env.REACT_APP_ENV,
  token: process.env.REACT_APP_API_TOKEN,
  blockchainExplorer: 'https://www.blockchain.com',
  sentry: {
    dsn: 'https://51128a6a2ae949988eb350fc8b7977d6@sentry.apio.cloud/18'
  }
}

export default configuration
