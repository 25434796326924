import configuration from '../configuration.js'

// varibile di riferimenti per mi stabilisce i tagId che voglio vedere
let currentTagId = configuration.logTag || 'all'

// variabili con il css string per il console.log
const tagColor = 'background-color: #b501bf; color: #f7e4ff; font-weight: 500;'
const notagColor =
  'background-color: #4c4c4c; color: #f2f2f2; font-weight: 500;'
const okColor = 'background-color: #05900b; color: #edf7ed; font-weight: 500;'
const infoColor = 'background-color: #0465b3; color: #e8f4fd; font-weight: 500;'
const warnColor = 'background-color: #ffcc10; color: #462900; font-weight: 500;'
const errorColor =
  'background-color: #d21003; color: #fdecea; font-weight: 500;'

// funzione che posso inviare per cambiare il currentTagId
export const changeLogTagId = (newTagID) => {
  if (newTagID) {
    currentTagId = newTagID
  }
}

// creo la funzione che mi gestirà i log
export const alog = async (text, variable = 'start', tagId, type) => {
  // controllo se c'è almeno un testo o una variabile
  let finalText = ''
  if (text !== null) {
    finalText = text
  }
  if (text || variable !== 'start') {
    // controllo se c'è un tagId
    if (
      tagId === 'fix' ||
      (currentTagId !== 'hide' &&
        (currentTagId === 'all' || currentTagId === tagId))
    ) {
      switch (type) {
        case 'ok':
          if (tagId) {
            console.log(
              `%c ${tagId.toUpperCase()} %c OK %c ${finalText}`,
              tagColor,
              okColor,
              '',
              variable !== 'start' ? variable : ''
            )
          } else {
            console.log(
              `%c NOTAG %c OK %c ${finalText}`,
              notagColor,
              okColor,
              '',
              variable !== 'start' ? variable : ''
            )
          }
          break
        case 'info':
          if (tagId) {
            console.log(
              `%c ${tagId.toUpperCase()} %c INFO %c ${finalText}`,
              tagColor,
              infoColor,
              '',
              variable !== 'start' ? variable : ''
            )
          } else {
            console.log(
              `%c NOTAG %c INFO %c ${finalText}`,
              notagColor,
              infoColor,
              '',
              variable !== 'start' ? variable : ''
            )
          }
          break
        case 'warn':
          if (tagId) {
            console.log(
              `%c ${tagId.toUpperCase()} %c WARN %c ${finalText}`,
              tagColor,
              warnColor,
              '',
              variable !== 'start' ? variable : ''
            )
          } else {
            console.log(
              `%c NOTAG %c WARN %c ${finalText}`,
              notagColor,
              warnColor,
              '',
              variable !== 'start' ? variable : ''
            )
          }
          break
        case 'error':
          if (tagId) {
            console.log(
              `%c ${tagId.toUpperCase()} %c ERROR %c ${finalText}`,
              tagColor,
              errorColor,
              '',
              variable !== 'start' ? variable : ''
            )
          } else {
            console.log(
              `%c NOTAG %c ERROR %c ${finalText}`,
              notagColor,
              errorColor,
              '',
              variable !== 'start' ? variable : ''
            )
          }
          break
        default:
          if (tagId) {
            console.log(
              `%c ${tagId.toUpperCase()} %c ${finalText}`,
              tagColor,
              '',
              variable !== 'start' ? variable : ''
            )
          } else {
            console.log(
              `%c NOTAG %c ${finalText}`,
              notagColor,
              '',
              variable !== 'start' ? variable : ''
            )
          }
      }
    }
  }
}
