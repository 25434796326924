import React, { useContext } from 'react'
// import { makeStyles } from '@mui/styles'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'
// import { hideOrShowProperty } from '../../utils/utils'
import { Typography } from '@mui/material'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'

// const useStyles = makeStyles(presentationStyle)
const AttributesGrid = () => {
  // const classes = useStyles()
  const { state } = useContext(AppContext)
  const { product } = state
  const { t } = useTranslation('attributesGrid')

  return (
    <GridContainer style={presentationStyle.contentContainer}>
      <GridItem xs={12} md={12} xl={12}></GridItem>
      <GridItem xs={12} md={12} xl={12}>
        <Typography
          variant='h4'
          style={Object.assign({}, presentationStyle.fredoka, {
            margin: '20px 0 10px 0'
          })}
        >
          {`${t('description')}`}
        </Typography>
      </GridItem>
      <GridItem xs={12} md={12} xl={12}>
        {product && product.description
          ? product.description.includes('\n')
            ? product.description.split('\n').map((row) => {
                const rand = Math.random()
                return <p key={`${row} ${rand}`}>{row}</p>
              })
            : product.description
          : console.log('product', product.description)}
      </GridItem>
    </GridContainer>
  )
}
export default AttributesGrid
