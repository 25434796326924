import React from 'react'
// import { makeStyles } from '@mui/styles'
import { Container, Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle.js'

import bg from '../../assets/img/notFound.png'

// const useStyles = makeStyles(presentationStyle)

const NotFound = () => {
  // const classes = useStyles()
  const { t } = useTranslation('notFound')

  return (
    <div style={presentationStyle.bodySection}>
      <Container>
        <Box
          width='100%'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <div
            style={Object.assign({}, presentationStyle.notFoundContainer, {
              backgroundImage: `url(${bg})`
            })}
          ></div>
        </Box>
      </Container>
      <Box
        width='100%'
        mt={5}
        pr='40px'
        pl='40px'
        style={{ textAlign: 'center' }}
      >
        <Typography
          style={presentationStyle.h1Text}
          variant='h5'
          component='h1'
        >
          {t('message')}
        </Typography>
      </Box>
    </div>
  )
}

export default NotFound
