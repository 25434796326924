const REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export function uuidValidate(uuid) {
  return typeof uuid === 'string' && REGEX.test(uuid)
}

export const hideOrShowProperty = (discover, property, type) => {
  if (discover && Object.keys(discover).length > 0) {
    if (!discover.metadata.visibility[type][property]) {
      if (
        discover.metadata.visibility[type][property] === undefined ||
        discover.metadata.visibility[type][property] === null
      ) {
        return true
      }
    }
    return !!discover.metadata.visibility[type][property]
  } else {
    return true
  }
}
