import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppState'
import AttributesGrid from '../AttributesGrid/AttributesGrid'
import DescriptionSection from '../AttributesGrid/DescriptionSection'
import MediaSection from '../AttributesGrid/MediaSection'
import MediaSectionDesktop from '../AttributesGrid/MediaSectionDesktop'
import { Box } from '@mui/material'
import { hideOrShowProperty } from '../../utils/utils'
import PropTypes from 'prop-types'
const CharacteristicsPage = (props) => {
  const { state } = useContext(AppContext)
  const { sectionActive, discover } = state
  const { mobile } = props
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])

  return (
    <>
      {hideOrShowProperty(discover, 'attributes', 'product') && (
        <AttributesGrid />
      )}
      <Box component='span' display={sectionActive !== 1 ? 'none' : 'block'}>
        {hideOrShowProperty(discover, 'description', 'product') &&
          state.product?.description && <DescriptionSection />}
      </Box>
      {hideOrShowProperty(discover, 'additionalAttributes', 'product') &&
      mobile ? (
        <MediaSection />
      ) : (
        <MediaSectionDesktop />
      )}
    </>
  )
}

// Add PropTypes validation for 'mobile'
CharacteristicsPage.propTypes = {
  mobile: PropTypes.bool.isRequired
}

export default CharacteristicsPage
