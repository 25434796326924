import React, { useContext, useEffect } from 'react'
import { CardMedia, CardContent, useMediaQuery, Link } from '@mui/material'
// import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Card from '../Card/Card'
import Map from '../Map/Map'
import TextWithUrl from '../TextWithUrl/TextWithUrl'
import Skeleton from 'react-loading-skeleton'
import { AppContext } from '../../context/AppState'

import profile from '../../assets/img/img-placeholder.png'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'
import map from '../../assets/img/contacts/map.svg'
// import cart from '../../assets/img/contacts/cart.svg'
import site from '../../assets/img/contacts/site.svg'
import phone from '../../assets/img/contacts/phone.svg'
import email from '../../assets/img/contacts/email.svg'

// const useStyles = makeStyles(presentationStyle)

const CompanyInfo = () => {
  // const classes = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation('companyInfo')
  const { state } = useContext(AppContext)
  const { company, sectionActive } = state

  console.log('company', company)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])

  const extractProductionKey = (attributeKey) => {
    let translatedString = ''
    if (attributeKey.includes('productions')) {
      const productionsArray = attributeKey.split(' ')
      const [prodString, ...rest] = productionsArray
      translatedString = [t(prodString), ...rest].join(' ')
    } else {
      translatedString = t(attributeKey)
    }

    return translatedString
  }

  return (
    <GridContainer
      style={Object.assign(
        {},
        presentationStyle.contentContainer,
        isSmall
          ? { padding: 0, marginTop: '16px' }
          : { padding: 0, background: 'white' }
      )}
      spacing={4}
    >
      <GridItem xs={12} style={{ paddingTop: '26px' }}>
        <Card
          style={
            isSmall
              ? { padding: '20px', marginTop: 10, marginBottom: 20 }
              : {
                  padding: '20px 30px',
                  marginTop: 10,
                  marginBottom: 16,
                  boxShadow: 'none'
                }
          }
        >
          <GridContainer
            direction='column'
            justify='center'
            alignItems='center'
          >
            <GridItem style={presentationStyle.alignCenter} xs={12}>
              <CardMedia
                component='img'
                style={
                  isSmall
                    ? presentationStyle.cardMediaMobile
                    : presentationStyle.cardMedia
                }
                image={
                  (company && company.logo && company.logo.cropped
                    ? company.logo.cropped
                    : profile) || (
                    <Skeleton duration={0.7} width={300} height={300} />
                  )
                }
              />
            </GridItem>
            <GridItem style={presentationStyle.alignCenter} xs={12}>
              <h1
                style={Object.assign(
                  { padding: 15 },
                  presentationStyle.cardTitle
                )}
              >
                {company.name}
              </h1>
            </GridItem>
          </GridContainer>
          <CardContent style={{ padding: 0 }}>
            <h5 style={presentationStyle.cardTitleSmall}>{t('description')}</h5>
            {company.name && company.description && (
              <TextWithUrl
                className='companyDescriptionContainer'
                companyName={company.name}
                text={company.description}
              ></TextWithUrl>
            )}
            <GridContainer
              direction='row'
              justify='center'
              alignItems='flex-start'
            >
              <GridItem xs={12} md={6}>
                <h4 style={presentationStyle.cardTitleSmall}>
                  {t('titleInfo')}
                </h4>
                <p style={{ display: 'flex', alignItems: 'center' }}>
                  {company && company.geolocation.address && (
                    <>
                      <img
                        src={map}
                        alt='icon'
                        width='40'
                        style={{ marginRight: '15px' }}
                      />
                      {`${company.geolocation.address.street || ''}, ${
                        company.geolocation.address.city || ''
                      }`}
                    </>
                  )}
                  <br></br>
                </p>
                {company && company.email ? (
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={email}
                      alt='icon'
                      width='40'
                      style={{ marginRight: '15px' }}
                    />
                    <Link color='inherit' href={`mailto:${company.email}`}>
                      {company.email}
                    </Link>
                  </p>
                ) : (
                  ''
                )}
                {company && company.website ? (
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={site}
                      alt='icon'
                      width='40'
                      style={{ marginRight: '15px' }}
                    />
                    <Link color='inherit' href={`${company.website}`}>
                      {company.website}
                    </Link>
                  </p>
                ) : (
                  ''
                )}
                {/* {company && company.ecommerce ? (
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={cart}
                      alt='cart'
                      width='40'
                      style={{ marginRight: '15px' }}
                    />
                    <Link color='inherit' href={`${company.ecommerce}`}>
                      {company.ecommerce}
                    </Link>
                  </p>
                ) : (
                  ''
                )} */}
                {company && company.phoneNumber ? (
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={phone}
                      alt='icon'
                      width='40'
                      style={{ marginRight: '15px' }}
                    />
                    <Link color='inherit' href={`tel:${company.phoneNumber}`}>
                      {company.phoneNumber}
                    </Link>
                  </p>
                ) : (
                  ''
                )}
                {company && company.mobileNumber ? (
                  <p style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={phone}
                      alt='icon'
                      width='40'
                      style={{ marginRight: '15px' }}
                    />
                    <Link color='inherit' href={`tel:${company.mobileNumber}`}>
                      {company.mobileNumber}
                    </Link>
                  </p>
                ) : (
                  ''
                )}
                {company && company.attributes
                  ? company.attributes.map((attribute, index) => (
                      <p key={`attribute-${index}`}>
                        <span style={presentationStyle.boldText}>
                          {`${extractProductionKey(attribute.name)}: `}
                        </span>
                        {attribute.value}{' '}
                        {attribute.name === 'surface' ? t('surfaceUnit') : ''}
                      </p>
                    ))
                  : ''}
              </GridItem>

              {company &&
              company.geolocation &&
              company.geolocation.lat &&
              company.geolocation.lng ? (
                <GridItem xs={12} md={6}>
                  <h4 style={presentationStyle.cardTitleSmall}>{t('map')}</h4>
                  <div style={presentationStyle.companyImgContainer}>
                    <Map
                      zoom={14}
                      isHeader={true}
                      lat={String(company.geolocation.lat)}
                      lng={String(company.geolocation.lng)}
                    />
                  </div>
                </GridItem>
              ) : (
                ''
              )}
            </GridContainer>
          </CardContent>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default CompanyInfo
