import i18n from '../i18n'
import { alog } from './apioLog'
// VARIABLES
// variabile con la lingua attuale
export let currLang = getCurrLang()
alog('currLang => ', currLang, 'DEBUG', 'info')

// FUNCTIONS
// funzione che mi ritorna la lingua attuale
export function getCurrLang() {
  return i18n.language
}

// funzione che fa cambiare lingua
export function changeLanguage(lang) {
  alog('new lang => ', lang, 'DEBUG', 'info')
  if (!lang) {
    lang = 'it-IT'
  }
  i18n.changeLanguage(lang)
  currLang = getCurrLang()
}

// funzione che ritorna la lingua leggibile
// export function returnAbbr(lang) {
//   let abbr = 'Ita'
//   if (lang === 'en-GB' || lang === 'en-US' || lang === 'en') {
//     abbr = 'Eng'
//   } else if (lang === 'fr-FR' || lang === 'fr') {
//     abbr = 'Fra'
//   } else if (lang === 'de-DE' || lang === 'de') {
//     abbr = 'Deu'
//   }
//   return abbr
// }
export function returnAbbr(lang) {
  let abbr = 'Eng'

  switch (lang) {
    case 'en-GB':
    case 'en-US':
    case 'en':
      abbr = 'Eng'
      break
    case 'fr-FR':
    case 'fr':
      abbr = 'Fra'
      break
    case 'de-DE':
    case 'de':
      abbr = 'Deu'
      break
    case 'it':
    case 'it-IT':
      abbr = 'Ita'
      break
    case 'bg-BG':
      abbr = 'Bgr'
      break
    case 'cs-CZ':
      abbr = 'Ces'
      break
    case 'da-DK':
      abbr = 'Dan'
      break
    case 'el-GR':
      abbr = 'Ell'
      break
    case 'es-ES':
      abbr = 'Esp'
      break
    case 'et-EE':
      abbr = 'Est'
      break
    case 'fi-FI':
      abbr = 'Fin'
      break
    case 'ga-IE':
      abbr = 'Gle'
      break
    case 'hr-HR':
      abbr = 'Hrv'
      break
    case 'hu-HU':
      abbr = 'Hun'
      break
    case 'lt-LT':
      abbr = 'Lit'
      break
    case 'lv-LV':
      abbr = 'Lav'
      break
    case 'mt-MT':
      abbr = 'Mlt'
      break
    case 'nl-NL':
      abbr = 'Nld'
      break
    case 'pl-PL':
      abbr = 'Pol'
      break
    case 'pt-PT':
      abbr = 'Prt'
      break
    case 'ro-RO':
      abbr = 'Ron'
      break
    case 'sk-SK':
      abbr = 'Slk'
      break
    case 'sl-SI':
      abbr = 'Slv'
      break
    case 'sv-SE':
      abbr = 'Swe'
      break
    default:
      // Mantieni l'abbreviazione predefinita 'Ita' se non corrisponde a nessuna lingua specificata
      break
  }

  return abbr
}
export function returnAbbrShort(lang) {
  let abbr = 'En' // Modifico l'abbreviazione predefinita a due lettere

  switch (lang) {
    case 'en-GB':
    case 'en-US':
    case 'en':
      abbr = 'En'
      break
    case 'fr-FR':
    case 'fr':
      abbr = 'Fr'
      break
    case 'de-DE':
    case 'de':
      abbr = 'De'
      break
    case 'it':
    case 'it-IT':
      abbr = 'It'
      break
    case 'bg-BG':
      abbr = 'Bg'
      break
    case 'cs-CZ':
      abbr = 'Cs'
      break
    case 'da-DK':
      abbr = 'Da'
      break
    case 'el-GR':
      abbr = 'El'
      break
    case 'es-ES':
      abbr = 'Es'
      break
    case 'et-EE':
      abbr = 'Et'
      break
    case 'fi-FI':
      abbr = 'Fi'
      break
    case 'ga-IE':
      abbr = 'Ga'
      break
    case 'hr-HR':
      abbr = 'Hr'
      break
    case 'hu-HU':
      abbr = 'Hu'
      break
    case 'lt-LT':
      abbr = 'Lt'
      break
    case 'lv-LV':
      abbr = 'Lv'
      break
    case 'mt-MT':
      abbr = 'Mt'
      break
    case 'nl-NL':
      abbr = 'Nl'
      break
    case 'pl-PL':
      abbr = 'Pl'
      break
    case 'pt-PT':
      abbr = 'Pt'
      break
    case 'ro-RO':
      abbr = 'Ro'
      break
    case 'sk-SK':
      abbr = 'Sk'
      break
    case 'sl-SI':
      abbr = 'Sl'
      break
    case 'sv-SE':
      abbr = 'Sv'
      break
    default:
      // Mantieni l'abbreviazione predefinita 'It' se non corrisponde a nessuna lingua specificata
      break
  }

  return abbr
}

export function returnAbbrTwo(lang) {
  let abbr = 'it'
  if (lang === 'en-GB' || lang === 'en-US' || lang === 'fr-FR') {
    abbr = 'en'
  }
  return abbr
}

// funzione che ritorna la lingua leggibile
export function returnLangName(lang) {
  let name = 'Italiano'
  if (lang === 'en-GB' || lang === 'en-US') {
    name = 'English'
  }
  return name
}
