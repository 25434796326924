import React, { useContext, useEffect } from 'react'
// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import { AppContext } from '../../context/AppState'
import { useTranslation } from 'react-i18next'

import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'

import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

// const useStyles = makeStyles(presentationStyle)

const Disposal = () => {
  // const classes = useStyles()
  const isSmall = useMediaQuery('max-height: 600px')

  const { state } = useContext(AppContext)
  const { product, sectionActive } = state

  const { t } = useTranslation('wasteTable')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])

  const waste = product.waste
  const isWasteSecondaryComponentHide =
    product.metadata?.isWasteSecondaryComponentHide

  function getMaterialName(name) {
    const result = name.slice(name.indexOf('-') + 1).trim()

    return result
  }

  return (
    <div
      style={Object.assign(
        {},
        presentationStyle.lotContainer,
        isSmall ? { marginTop: '16px' } : { background: 'white' }
      )}
    >
      {/* <Typography variant='h5' sx={{ mb: 2 }}>Smaltimento</Typography> */}
      <GridContainer
        style={{ width: '100%' }}
        direction='row'
        justify='center'
        alignItems='stretch'
        spacing={6}
      >
        {waste.map((el, i) => (
          <GridItem
            key={`waste-${i}`}
            xs={12}
            md={4}
            lg={4}
            style={isSmall && { marginBottom: 3 }}
          >
            <Card className='wasteCard'>
              <Box
                sx={{
                  padding: 2,
                  background:
                    el.wasteCollection === 'glass'
                      ? '#3D8443'
                      : el.wasteCollection === 'paper'
                      ? '#0D5EA6'
                      : el.wasteCollection === 'organic_waste'
                      ? '#674B42'
                      : el.wasteCollection === 'plastic'
                      ? '#FCD302'
                      : el.wasteCollection === 'dry_undifferentiated'
                      ? '#969696'
                      : el.wasteCollection === 'metals'
                      ? '#2894AE'
                      : '#EF7C3C'
                }}
              >
                <Typography
                  variant='h5'
                  color='white'
                  sx={{ textTransform: 'uppercase' }}
                >
                  {/* {el.wasteCollection === 'glass'
                    ? 'Vetro'
                    : el.wasteCollection === 'paper'
                    ? 'Carta'
                    : el.wasteCollection === 'organic_waste'
                    ? 'Organico'
                    : el.wasteCollection === 'plastic'
                    ? 'Plastica'
                    : el.wasteCollection === 'regional_disposition'
                    ? 'Disposizioni regionali'
                    : 'Componente'} */}
                  {`${t(`wasteCollectionType.${el.wasteCollection}`)}`}
                </Typography>
              </Box>
              <CardContent>
                <Typography variant='h5'>
                  {/* {el.component === 'bottle'
                    ? 'Bottiglia'
                    : el.component === 'cap'
                    ? 'Tappo'
                    : el.component === 'label'
                    ? 'Etichetta'
                    : el.component === 'capsule'
                    ? 'Capsula'
                    : el.component === 'bigTray'
                    ? 'Vassoio'
                    : 'Componente'} */}

                  {`${t(`componentType.${el.component}`)}`}
                </Typography>

                <br />
                <Typography variant='body'>
                  <b>{`${t('prevalentMaterial')}`}</b>
                  <br />
                  {el.material &&
                    getMaterialName(`${t(`materials.${el.material.name}`)}`)}

                  {''}
                  <br />
                  <br />

                  {el.material &&
                    Object.keys(el.material)?.length > 0 &&
                    el.material?.materials?.length > 0 && (
                      <>
                        <b>{`${t('code')}`}</b>
                        <br />
                        {el.material.materials[0].code}
                      </>
                    )}
                  <br />

                  {el.material &&
                    Object.keys(el.material)?.length > 0 &&
                    el.material?.materials?.length > 0 &&
                    el.material?.materials[0]?.code.startsWith('GL') && (
                      <>
                        <br />
                        <b>{`${t('specificMaterial')}`}</b>
                        <br />
                        {t(`materials.${el.material.materials[0].name}`)}
                      </>
                    )}
                </Typography>
                <Typography variant='h6' fontSize={14} mb={3}>
                  <br />
                  <b>{`${t('typeOfComponent')}`}</b>
                  <br />
                  {/* {el.componentType === 'monocomponent'
                    ? 'Monocomponente'
                    : el.componentType === 'multicomponent'
                    ? 'Multicomponente'
                    : ''} */}

                  {el.componentType === 'monocomponent'
                    ? t('materials.monoComponent')
                    : el.componentType === 'multicomponent'
                    ? t('materials.multiComponent')
                    : ''}
                </Typography>
                <Box py={1}></Box>
                {el.componentType === 'multicomponent' &&
                  !isWasteSecondaryComponentHide && (
                    <Typography variant='body'>
                      <b>{`${t('otherMaterials')}`}</b>
                      <br />
                      {/* {el.material.materials.map((el, i) =>
                      t(`materials.${el.name}`)
                    )} */}
                      {el?.material &&
                        Object.keys(el?.material)?.length > 0 &&
                        el?.material?.materials?.length > 0 &&
                        t(`materials.${el.material.materials[0].name}`)}
                    </Typography>
                  )}
                {/* <Box py={1}></Box>
                <Typography variant="body" color={'#969696'}>
                  <IconButton size="small" style={{ color: '#969696' }}>
                    <InfoIcon />
                  </IconButton>
                  {t(`checkMunicipalProvisions`)}
                </Typography> */}
              </CardContent>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
      <Box sx={{ pt: 4, textAlign: 'center' }}>
        <Typography variant='body' color={'#969696'}>
          <IconButton size='small' style={{ color: '#969696' }}>
            <InfoIcon />
          </IconButton>
          {t('checkMunicipalProvisions')}
        </Typography>
      </Box>
    </div>
  )
}

export default Disposal
