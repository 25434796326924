import React, { useContext, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
// import { makeStyles } from '@mui/styles'
// import MaterialTable from 'material-table'

import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import { AppContext } from '../../context/AppState'
// const useStyles = makeStyles(presentationStyle)

// colonne per tabella certificazione

const CertificationsTable = () => {
  // const classes = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { state } = useContext(AppContext)

  const { sectionActive } = state

  // funzione che apre modal documenti

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [sectionActive])

  return (
    <div
      style={Object.assign(
        {},
        presentationStyle.lotContainer,
        isSmall && { marginTop: '16px' }
      )}
    >
      <GridContainer style={{ width: '100%' }}>
        <GridItem
          xs={12}
          style={isSmall ? { paddingLeft: '2px', paddingRight: '2px' } : {}}
        >
          <div className='certificationsTable showTitle'>
            {/* {hideOrShowProperty(discover, 'certifications', 'product') && <MaterialTable
            title={t('title')}
            localization={tableCurrLang}
            columns={isSmall ? certMobileColumns : certColumns}
            data={product && product.certifications}
            options={{
              emptyRowsWhenPaging: false,
              pageSize: 10,
              pageSizeOptions: [10, 20, 30],
              showEmptyDataSourceMessage: true
            }}
            onRowClick={
              (event, rowData) => handleOpenCert(rowData)
            }
          />
          } */}
            Qui VA LA TABELLA
          </div>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default CertificationsTable
