import React, { useContext, useEffect } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'

import { AppContext } from '../../context/AppState'

const Menu = () => {
  const isSmall = useMediaQuery('(max-width: 600px)')
  const { state, dispatch } = useContext(AppContext)

  const getSectionDynamically = () => {
    let toReturn = 4
    if (
      Object.keys(state.product?.nutritionalValue)?.length > 0 ||
      state?.product?.ingredients?.metadata?.isAromaticWine ||
      state?.product?.ingredients?.values?.length > 0
    ) {
      toReturn = 4 //section prodotto
    } else if (state.product?.attributes?.length > 0) {
      toReturn = 1 //section caratteristiche
    } else if (state.product?.waste?.length > 0) {
      toReturn = 6
    } // section smaltimento
    else if (!state.product?.metadata?.isCompanyFrontendHide) {
      toReturn = 2 //section azienda
    }
    return toReturn
  }

  useEffect(() => {
    dispatch({
      type: 'SET_MENU_SECTION',
      payload: getSectionDynamically()
    })
  }, [])

  return isSmall ? <MenuMobile /> : <MenuDesktop />
}

export default Menu
