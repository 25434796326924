import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @mui/material components
// import { makeStyles } from "@mui/styles";
import Grid from '@mui/material/Grid'

import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'

// const styles = {
//   grid: {
//     position: "relative",
//     width: "100%",
//     minHeight: "1px",
//     paddingRight: "15px",
//     paddingLeft: "15px"
//     /* flexBasis: "auto" */
//   }
// };

// const useStyles = makeStyles(styles);

export default function GridItem(props) {
  const { children, className, ...rest } = props
  // const classes = useStyles();
  return (
    <Grid item {...rest} className={className} style={presentationStyle.grid}>
      {children}
    </Grid>
  )
}

GridItem.defaultProps = {
  className: ''
}

GridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
