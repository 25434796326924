/* eslint-disable react/jsx-key */

import React, { useContext, useEffect, useState } from 'react'
// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AppContext } from '../../context/AppState'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { currLang } from '../../utils/translations'

import { getIngredients, getAzureTranslation } from 'actions/AppActions'
import { uuidValidate } from 'utils/utils'

const IngredientsSection = () => {
  const { state } = useContext(AppContext)
  const { product } = state

  const [allIngredients, setAllIngredients] = useState([])
  const [initDone, setInitDone] = useState(false)
  const [translationLoaded, setTranslationLoaded] = useState(false)

  const [rawMaterials, setRawMaterials] = useState([])
  const [acidityRegulators, setAcidityRegulators] = useState([])
  const [preservatives, setPreservatives] = useState([])
  const [antioxidants, setAntioxidants] = useState([])
  const [agentsStabilising, setAgentsStabilising] = useState([])
  const [gasses, setGasses] = useState([])
  const [others, setOthers] = useState([])
  const [otherCategory, setOtherCategory] = useState([])

  const [rawMaterialsIngredients, setRawMaterialsIngredients] = useState([])
  const [acidityRegulatorsIngredients, setAcidityRegulatorsIngredients] =
    useState([])
  const [preservativesIngredients, setPreservativesIngredients] = useState([])
  const [antioxidantsIngredients, setAntioxidantsIngredients] = useState([])
  const [agentsStabilisingIngredients, setAgentsStabilisingIngredients] =
    useState([])
  const [gassesIngredients, setGassesIngredients] = useState([])
  const [othersIngredients, setOthersIngredients] = useState([])
  const [othersCategoryIngredients, setOthersCategoryIngredients] = useState([])
  const [isBottledInProtectiveAtmosphere, setIsBottledInProtectiveAtmosphere] =
    useState(false)
  const [isRawMaterialTitleHide, setIsRawMaterialTitleHide] = useState(false)

  const [order, setOrder] = useState([
    'rawMaterials',
    'acidityRegulators',
    'preservatives',
    'antioxidants',
    'agentsStabilising',
    'gasses',
    'others',
    'othersIngredients'
  ])

  const { t } = useTranslation('ingredients')
  let rawMaterialsIngredientsTemp = []
  let acidityRegulatorsIngredientsTemp = []
  let preservativesIngredientsTemp = []
  let antioxidantsIngredientsTemp = []
  let agentsStabilisingIngredientsTemp = []
  let gassesIngredientsTemp = []
  let othersIngredientsTemp = []
  let otherCategoryIngredientsTemp = []
  let isBottledInProtectiveAtmosphereTemp
  let isRawMaterialTitleHideTemp
  let orderTemp = []

  const useFetchData = () => {
    useEffect(() => {
      const fetchIngredients = async () => {
        const ingredientsResponse = await getIngredients()
        setAllIngredients(ingredientsResponse)
      }
      fetchIngredients()
    }, [])
  }

  useFetchData()

  useEffect(() => {
    if (allIngredients && Object.keys(allIngredients).length > 0) {
      const result = {}
      for (const key in allIngredients.values) {
        if (Object.prototype.hasOwnProperty.call(allIngredients.values, key)) {
          result[key] = Object.keys(allIngredients.values[key])
        }
      }
      console.log('result ---> ', result)
      setRawMaterials(result.rawMaterials)
      setAcidityRegulators(result.acidityRegulators)
      setPreservatives(result.preservatives)
      setAgentsStabilising(result.agentsStabilising)
      setAntioxidants(result.antioxidants)
      setGasses(result.gasses)
      setOthers(result.others)
      setOtherCategory(result.othersIngredients)
      setInitDone(true)
    }
  }, [allIngredients])

  useEffect(() => {
    setTranslationLoaded(false)
  }, [currLang])

  useEffect(() => {
    if (initDone) {
      if (
        product.ingredients &&
        product.ingredients.values &&
        product.ingredients.values.length > 0
      ) {
        const fetchTranslation = async (product) => {
          const data = []
          for (const el of product.ingredients.values) {
            if (uuidValidate(el)) {
              const elem = product.includes.userDefinedIngredients.find(
                (value) => value.uuid === el
              )
              data.push({ uuid: el, text: elem.value })
            }
          }
          if (data.length) {
            const dataToSend = data.map((el) => ({ text: el.text }))
            const translation = await getAzureTranslation(dataToSend, {
              'api-version': '3.0',
              to: currLang.split('-')[0]
            })
            data.forEach((el, index) => {
              const elem = product.includes.userDefinedIngredients.find(
                (value) => value.uuid === el.uuid
              )
              elem.translation = translation[index].translations[0].text
            })
          }
          setTranslationLoaded(true)
        }
        fetchTranslation(product)
      }
    }
  }, [currLang, initDone])

  useEffect(() => {
    /* for (const el of product.attributes) {
      if (el.name.includes('<VN>')) {
        values.push(el)
      }
    } */

    if (translationLoaded) {
      if (product.ingredients) {
        rawMaterialsIngredientsTemp =
          product.ingredients &&
          product.ingredients.values
            .filter((el) => rawMaterials.includes(el))
            .map((x) => t(`components.${x}`))
            .reduce((prev, curr, index) => {
              return index !== rawMaterialsIngredientsTemp.length - 1
                ? [...prev, curr, ', ']
                : [...prev, curr]
            }, [])
        rawMaterialsIngredientsTemp.pop() // remove last comma

        acidityRegulatorsIngredientsTemp =
          product.ingredients &&
          product.ingredients.values
            .filter((el) => acidityRegulators.includes(el))
            .map((x) => t(`components.${x}`))
            .reduce((prev, curr, index) => {
              return index !== acidityRegulatorsIngredientsTemp.length - 1
                ? [...prev, curr, ` ${t('andOr')} `]
                : [...prev, curr]
            }, [])
        acidityRegulatorsIngredientsTemp.pop() // remove last andOr
        preservativesIngredientsTemp =
          product.ingredients &&
          product.ingredients.values
            .filter((el) => preservatives.includes(el))
            .map((x) => {
              if (
                x === 'lysozyme' ||
                x === 'potassiumBisulphite' ||
                x === 'sulphurDioxide2' ||
                x === 'potassiumMetabisulphite' ||
                x === 'sulphites'
              ) {
                return <b>{t(`components.${x}`)}</b>
              } else {
                return t(`components.${x}`)
              }
            })
            // .join(` ${t('andOr')} `)
            .reduce((prev, curr, index) => {
              return index !== preservativesIngredientsTemp.length - 1
                ? [...prev, curr, ', ']
                : [...prev, curr]
            }, [])
        preservativesIngredientsTemp.pop() // remove last comma

        antioxidantsIngredientsTemp =
          product.ingredients &&
          product.ingredients.values
            .filter((el) => antioxidants.includes(el))
            .map((x) => {
              if (
                x === 'lysozyme2' ||
                x === 'potassiumBisulphite2' ||
                x === 'sulphurDioxide3' ||
                x === 'potassiumMetabisulphite2' ||
                x === 'sulphites2'
              ) {
                return <b>{t(`components.${x}`)}</b>
              } else {
                return t(`components.${x}`)
              }
            })
            // .join(` ${t('andOr')} `)
            .reduce((prev, curr, index) => {
              return index !== antioxidantsIngredientsTemp.length - 1
                ? [...prev, curr, ', ']
                : [...prev, curr]
            }, []) // remove last comma
        antioxidantsIngredientsTemp.pop()

        agentsStabilisingIngredientsTemp =
          product.ingredients &&
          product.ingredients.values
            .filter((el) => agentsStabilising.includes(el))
            .map((x) => t(`components.${x}`))
            .reduce((prev, curr, index) => {
              return index !== agentsStabilisingIngredientsTemp.length - 1
                ? [...prev, curr, ` ${t('andOr')} `]
                : [...prev, curr]
            }, []) // remove last andOr
        agentsStabilisingIngredientsTemp.pop()

        gassesIngredientsTemp =
          product.ingredients &&
          product.ingredients.values
            .filter((el) => gasses.includes(el))
            .map((x) => t(`components.${x}`))
            .reduce((prev, curr, index) => {
              return index !== gassesIngredientsTemp.length - 1
                ? [...prev, curr, ', ']
                : [...prev, curr]
            }, [])
        gassesIngredientsTemp.pop() // remove last comma

        othersIngredientsTemp =
          product.ingredients &&
          product.ingredients.values
            .filter((el) => others.includes(el))
            .map((x) => t(`components.${x}`))
            .reduce((prev, curr, index) => {
              return index !== othersIngredientsTemp.length - 1
                ? [...prev, curr, ', ']
                : [...prev, curr]
            }, [])
        othersIngredientsTemp.pop() // remove last comma

        otherCategoryIngredientsTemp =
          product.ingredients &&
          product.ingredients.values
            .filter((el) => otherCategory.includes(el))
            .map((x) => t(`components.${x}`))
            .reduce((prev, curr, index) => {
              return index !== otherCategoryIngredientsTemp.length - 1
                ? [...prev, curr, ', ']
                : [...prev, curr]
            }, [])
        otherCategoryIngredientsTemp.pop() // remove last comma

        isBottledInProtectiveAtmosphereTemp =
          product.ingredients.metadata &&
          product.ingredients.metadata.isBottledInProtectiveAtmosphere
        isRawMaterialTitleHideTemp =
          product.metadata && product.metadata.isRawMaterialTitleHide
        orderTemp =
          product.ingredients.metadata &&
          product.ingredients.metadata.order &&
          product.ingredients.metadata.order.length
            ? product.ingredients.metadata.order
            : order
      }

      console.log('ORDER TEMP:::::', orderTemp)
      const ingredients = {
        rawMaterials: rawMaterialsIngredientsTemp,
        acidityRegulators: acidityRegulatorsIngredientsTemp,
        preservatives: preservativesIngredientsTemp,
        agentsStabilising: agentsStabilisingIngredientsTemp,
        preservativesAndAntioxidants: [], // NOTE: questo è solo per backward compatibility con una vecchia impostazione. Prima di rimuovere, sistemare su db i dati sporchi
        antioxidants: antioxidantsIngredientsTemp,
        gasses: gassesIngredientsTemp,
        others: othersIngredientsTemp,
        othersIngredients: otherCategoryIngredientsTemp
      }

      const filteredOrder = orderTemp.filter(
        (category) => ingredients[category].length > 0
      )

      setRawMaterialsIngredients(rawMaterialsIngredientsTemp)
      setAcidityRegulatorsIngredients(acidityRegulatorsIngredientsTemp)
      setPreservativesIngredients(preservativesIngredientsTemp)
      setAntioxidantsIngredients(antioxidantsIngredientsTemp)
      setAgentsStabilisingIngredients(agentsStabilisingIngredientsTemp)
      setGassesIngredients(gassesIngredientsTemp)
      setOthersIngredients(othersIngredientsTemp)
      setOthersCategoryIngredients(otherCategoryIngredientsTemp)
      setIsBottledInProtectiveAtmosphere(isBottledInProtectiveAtmosphereTemp)
      setIsRawMaterialTitleHide(isRawMaterialTitleHideTemp)
      setOrder(filteredOrder)

      console.log('ORDER TEMP:::::', orderTemp)
    }
    // eslint-disable-next-line
  }, [currLang, translationLoaded])

  const isSmall = useMediaQuery('(max-width: 600px)')

  return (
    <div
      style={Object.assign(
        {},
        presentationStyle.ingredientContainer,
        isSmall ? { marginTop: '16px' } : { background: 'white' }
      )}
    >
      {product.ingredients &&
        product.ingredients.values &&
        product.ingredients.values.length > 0 && (
          <>
            <Typography variant='h5' sx={{ mb: 1 }}>
              {t('title')}
            </Typography>
            {order.map((el, index) => {
              switch (el) {
                case 'rawMaterials': {
                  let rawMaterialsIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (rawMaterialsIngredients.length > 0) {
                      rawMaterialsIngredientsWithComma = rawMaterialsIngredients
                      rawMaterialsIngredientsWithComma.push(',')
                    }
                  } else {
                    if (rawMaterialsIngredients.length > 0) {
                      rawMaterialsIngredientsWithComma = rawMaterialsIngredients
                      rawMaterialsIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    rawMaterialsIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {!isRawMaterialTitleHide
                          ? `${t('category.rawMaterials')}: `
                          : ''}
                        {rawMaterialsIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'acidityRegulators': {
                  let acidityRegulatorsIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (acidityRegulatorsIngredients.length > 0) {
                      acidityRegulatorsIngredientsWithComma =
                        acidityRegulatorsIngredients
                      acidityRegulatorsIngredientsWithComma.push(';')
                    }
                  } else {
                    if (acidityRegulatorsIngredients.length > 0) {
                      acidityRegulatorsIngredientsWithComma =
                        acidityRegulatorsIngredients
                      acidityRegulatorsIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    acidityRegulatorsIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('category.acidityRegulators')}:{' '}
                        {acidityRegulatorsIngredientsWithComma.length > 2
                          ? `${t('contain')} `
                          : ''}
                        {acidityRegulatorsIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'preservatives': {
                  let preservativesIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (preservativesIngredients.length > 0) {
                      preservativesIngredientsWithComma =
                        preservativesIngredients
                      preservativesIngredientsWithComma.push(';')
                    }
                  } else {
                    if (preservativesIngredients.length > 0) {
                      preservativesIngredientsWithComma =
                        preservativesIngredients
                      preservativesIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    preservativesIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('category.preservatives')}:{' '}
                        {preservativesIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'antioxidants': {
                  let antioxidantsIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (antioxidantsIngredients.length > 0) {
                      antioxidantsIngredientsWithComma = antioxidantsIngredients
                      antioxidantsIngredientsWithComma.push(';')
                    }
                  } else {
                    if (antioxidantsIngredients.length > 0) {
                      antioxidantsIngredientsWithComma = antioxidantsIngredients
                      antioxidantsIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    antioxidantsIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('category.antioxidants')}:{' '}
                        {antioxidantsIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'agentsStabilising': {
                  let agentsStabilisingIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (agentsStabilisingIngredients.length > 0) {
                      agentsStabilisingIngredientsWithComma =
                        agentsStabilisingIngredients
                      agentsStabilisingIngredientsWithComma.push(';')
                    }
                  } else {
                    if (agentsStabilisingIngredients.length > 0) {
                      agentsStabilisingIngredientsWithComma =
                        agentsStabilisingIngredients
                      agentsStabilisingIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    agentsStabilisingIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('category.agentsStabilising')}:{' '}
                        {agentsStabilisingIngredientsWithComma.length > 2
                          ? `${t('contain')}  `
                          : ''}
                        {agentsStabilisingIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'gasses': {
                  let gassesIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (gassesIngredients.length > 0) {
                      gassesIngredientsWithComma = gassesIngredients
                      gassesIngredientsWithComma.push(';')
                    }
                  } else {
                    if (gassesIngredients.length > 0) {
                      gassesIngredientsWithComma = gassesIngredients
                      gassesIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    gassesIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('category.gasses')}: {gassesIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'others': {
                  let othersIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (othersIngredients.length > 0) {
                      othersIngredientsWithComma = othersIngredients
                      othersIngredientsWithComma.push(';')
                    }
                  } else {
                    if (othersIngredients.length > 0) {
                      othersIngredientsWithComma = othersIngredients
                      othersIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    othersIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('category.others')}: {othersIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                case 'othersIngredients': {
                  let othersCategoryIngredientsWithComma = []
                  if (parseInt(index) !== order.length - 1) {
                    if (othersCategoryIngredients.length > 0) {
                      othersCategoryIngredientsWithComma =
                        othersCategoryIngredients
                      othersCategoryIngredientsWithComma.push(';')
                    }
                  } else {
                    if (othersCategoryIngredients.length > 0) {
                      othersCategoryIngredientsWithComma =
                        othersCategoryIngredients
                      othersCategoryIngredientsWithComma.push('.')
                    }
                  }
                  return (
                    othersCategoryIngredientsWithComma.length > 0 && (
                      <Typography variant='body' key={index}>
                        {t('category.othersIngredients')}:{' '}
                        {othersCategoryIngredientsWithComma}
                      </Typography>
                    )
                  )
                }
                default: {
                  return null
                }
              }
            })}
            <br />
            {isBottledInProtectiveAtmosphere && (
              <Typography variant='body'>
                {t('category.isBottledInProtectiveAtmosphere')}.
              </Typography>
            )}
            {/* {rawMaterialsIngredients.length > 0 && <Typography variant='body' ><b>{t(`category.rawMaterials`)}</b>: {rawMaterialsIngredients}</Typography>}
        {acidityRegulatorsIngredients.length > 0 && <Typography variant='body' ><b>{t(`category.acidityRegulators`)}</b>: {t(`contain`)} {acidityRegulatorsIngredients}</Typography>}
        {preservativesAndAntioxidantsIngredients.length > 0 && <Typography variant='body'><b>{t(`category.preservativesAndAntioxidants`)}</b>: {t(`contain`)} {preservativesAndAntioxidantsIngredients}</Typography>}
        {agentsStabilisingIngredients.length > 0 && <Typography variant='body'><b>{t(`category.agentsStabilising`)}</b>: {t(`contain`)} {agentsStabilisingIngredients}</Typography>}
        {gassesIngredients.length > 0 && <Typography variant='body'><b>{t(`category.gasses`)}: {t(`contain`)} {gassesIngredients}</b></Typography>}
        {othersIngredients.length > 0 && <Typography variant='body'><b>{t(`category.others`)}: {t(`contain`)} {othersIngredients}</b></Typography>}
        {othersCategoryIngredients.length > 0 && <Typography variant='body'><b>{t(`category.othersIngredients`)}</b>: {othersCategoryIngredients}</Typography>}
        */}
            {/* <Typography variant='body' sx={{ mb:2,  textAlign:"center"}}>{valuesArray}</Typography> */}
            {/* <Typography variant='body' sx={{ mb: othersArray? 1:2,  textAlign:"center"}}>{valuesArray}</Typography>
        <Typography variant='body' sx={{ mb: 2,  textAlign:"center"}}>{"Contiene: "+othersArray}</Typography> */}
          </>
        )}
    </div>
  )
}
export default IngredientsSection
