import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// OPTIONAL
// import Backend from 'i18next-xhr-backend' // used to have a pre-render server side with multiple files
// https://github.com/i18next/i18next-xhr-backend
import LanguageDetector from 'i18next-browser-languagedetector'
// https://github.com/i18next/i18next-browser-languageDetector
import italianCommon from './translations/it/common.json'
import englishCommon from './translations/en/common.json'
import franceCommon from './translations/fr/common.json'
// import deuCommon from './translations/de/common.json'
import bgCommon from './translations/bg/common.json'
import cesCommon from './translations/cs/common.json'
import danCommon from './translations/da/common.json'
import deuCommon from './translations/de/common.json'
import ellCommon from './translations/el/common.json'
// import engCommon from './translations/en/common.json';
import espCommon from './translations/es/common.json'
import estCommon from './translations/et/common.json'
import finCommon from './translations/fi/common.json'
// import fraCommon from './translations/fr/common.json';
import gleCommon from './translations/ga/common.json'
import hrvCommon from './translations/hr/common.json'
import hunCommon from './translations/hu/common.json'
// import itaCommon from './translations/it/common.json';
import litCommon from './translations/lt/common.json'
import lavCommon from './translations/lv/common.json'
import mltCommon from './translations/mt/common.json'
import nldCommon from './translations/nl/common.json'
import polCommon from './translations/pl/common.json'
import prtCommon from './translations/pt/common.json'
import ronCommon from './translations/ro/common.json'
import slkCommon from './translations/sk/common.json'
import slvCommon from './translations/sl/common.json'
import sweCommon from './translations/sv/common.json'

// Some component functions
// https://www.i18next.com/overview/api#addresourcebundle

// variaible con le opzioni del LanguageDetector
const languageDetectorOptions = {
  // order and from where user language should be detected
  order: [
    'navigator',
    'localStorage',
    'cookie',
    'querystring',
    'htmlTag',
    'path',
    'subdomain'
  ],
  // cache user language on
  caches: ['localStorage', 'cookie'],
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  // optional expire and domain for set cookie
  cookieMinutes: 10,
  // cookieDomain: 'myDomain',
  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement

  // only detect languages that are in the whitelist
  // checkWhitelist: true
}

// variabile con le risorse
// const resources = {
//   it: italianCommon,
//   en: englishCommon,
//   fr: franceCommon,
//   de: deuCommon
// }
const resources = {
  it: italianCommon,
  en: englishCommon,
  fr: franceCommon,
  de: deuCommon,
  bg: bgCommon,
  cs: cesCommon,
  da: danCommon,
  el: ellCommon,
  es: espCommon,
  et: estCommon,
  fi: finCommon,
  ga: gleCommon,
  hr: hrvCommon,
  hu: hunCommon,
  lt: litCommon,
  lv: lavCommon,
  mt: mltCommon,
  nl: nldCommon,
  pl: polCommon,
  pt: prtCommon,
  ro: ronCommon,
  sk: slkCommon,
  sl: slvCommon,
  sv: sweCommon
}
// create component istance
i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      fallbackLng: {
        // 'it-IT': ['it'],
        // 'fr-FR': ['fr'],
        // 'de-DE': ['de'],
        'bg-BG': ['bg'], // Bulgaro
        'cs-CZ': ['cs'], // Ceco
        'da-DK': ['da'], // Danese
        'de-DE': ['de'], // Tedesco
        'el-GR': ['el'], // greco
        'es-ES': ['es'], // Spagnolo
        'et-EE': ['et'], // Estone
        'fi-FI': ['fi'], // Finlandese
        'fr-FR': ['fr'], // Francese
        'ga-IE': ['ga'], // Irlandese
        'hr-HR': ['hr'], // Croato
        'hu-HU': ['hu'], // Ungherese
        'it-IT': ['it'], // Italiano
        'lt-LT': ['lt'], // Lituano
        'lv-LV': ['lv'], // Lettone
        'mt-MT': ['mt'], // Maltese
        'nl-NL': ['nl'], // Dutch (Netherlands)
        'pl-PL': ['pl'], // Polacco
        'pt-PT': ['pt'], // Portoghese
        'ro-RO': ['ro'], // Rumeno
        'sk-SK': ['sk'], // Slovacco
        'sl-SI': ['sl'], // Sloveno
        'sv-SE': ['sv'], // Svedese
        default: ['en']
      },
      // lng: "it", // initialize i18next with language to use
      debug: true,
      // nonExplicitWhitelist: true, // if true will pass eg. en-US if finding en in whitelist
      detection: languageDetectorOptions, // per passare opzioni del languageDeterctor
      resources,

      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      }
    },
    (err, t) => {
      if (err) {
        return console.error(
          'something went wrong loading i18next, err => ',
          err
        )
      }
      t('key') // -> same as i18next.t
    }
  )

export default i18n
