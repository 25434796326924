import React, { useContext } from 'react'
// import { makeStyles } from '@mui/styles'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle.js'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppState'

import { hideOrShowProperty } from '../../utils/utils'
import { Box } from '@mui/material'
import Button from '../CustomButtons/Button.js'
// import productIcon from '../../assets/img/menu/product.svg'
import productIcon from '../../assets/img/menu/product.svg'
import companyIcon from '../../assets/img/menu/company.svg'
// import nutritionalIcon from '../../assets/img/menu/nutritional.svg'
import specificationsIcon from '../../assets/img/menu/nutritional.svg'
import disposalIcon from '../../assets/img/menu/disposal.svg'

// const useStyles = makeStyles(presentationStyle)

const MenuDesktop = () => {
  const { state, dispatch } = useContext(AppContext)

  const { sectionActive, discover } = state

  // funzione che cambia la sezione visualizzata
  const changeSection = (event, section) => {
    dispatch({
      type: 'SET_MENU_SECTION',
      payload: section
    })
  }
  // const classes = useStyles()
  const { t } = useTranslation('menu')
  return (
    <Box
      component='div'
      style={presentationStyle.menuContainer}
      sx={{
        marginBottom: 5,
        marginTop: 3,
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
      }}
    >
      <Box
        component='div'
        style={presentationStyle.mainMenu}
        sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
      >
        {hideOrShowProperty(discover, 'allergenes', 'product') &&
          (Object.keys(state.product.nutritionalValue).length > 0 ||
            state?.product?.ingredients?.metadata?.isAromaticWine ||
            state?.product?.ingredients?.values?.length > 0) && (
            <Button
              onClick={() => changeSection(null, 4)}
              style={presentationStyle.menuButtonPadding}
              color={sectionActive !== 4 ? 'transparent' : 'allergenesButton'}
            >
              <div style={presentationStyle.sectionButton}>
                <div style={{ marginBottom: '18px' }}>
                  <img
                    src={productIcon}
                    width='35'
                    alt='nutritional value icon'
                  />
                </div>
                <h6 style={{ color: '#3C4858' }}>{t('allergenes')}</h6>
              </div>
            </Button>
          )}
        {state.product.attributes?.length > 0 && (
          <Button
            onClick={() => changeSection(null, 1)}
            style={presentationStyle.menuButtonPadding}
            color={sectionActive !== 1 ? 'transparent' : 'productButton'}
          >
            <div style={presentationStyle.sectionButton}>
              <div style={{ marginBottom: '18px' }}>
                <img
                  src={specificationsIcon}
                  width='35'
                  alt='specifications icon'
                />
              </div>
              <h6 style={{ color: '#3C4858' }}>{t('specifications')}</h6>
            </div>
          </Button>
        )}
        {hideOrShowProperty(discover, 'waste', 'product') &&
        state.product.waste != null
          ? state.product.waste.length > 0 && (
              <Button
                onClick={() => changeSection(null, 6)}
                style={presentationStyle.menuButtonPadding}
                color={sectionActive !== 6 ? 'transparent' : 'productButton'}
              >
                <div style={presentationStyle.sectionButton}>
                  <div style={{ marginBottom: '18px' }}>
                    <img src={disposalIcon} width='35' alt='disposal icon' />
                  </div>
                  <h6 style={{ color: '#3C4858' }}>{t('waste')}</h6>
                </div>
              </Button>
            )
          : null}
        {!state.product.metadata?.isCompanyFrontendHide && (
          <Button
            onClick={() => changeSection(null, 2)}
            style={presentationStyle.menuButtonPadding}
            color={sectionActive !== 2 ? 'transparent' : 'companyButton'}
          >
            <div style={presentationStyle.sectionButton}>
              <div style={{ marginBottom: '18px' }}>
                <img src={companyIcon} width='35' alt='company icon' />
              </div>
              <h6 style={{ color: '#3C4858' }}>{t('company')}</h6>
            </div>
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default MenuDesktop
