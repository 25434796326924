import React, { useContext, useEffect, useState } from 'react'
// import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import { AppContext } from '../../context/AppState'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'
import './style.css'
import { Typography /* Box */ } from '@mui/material'
//import Card from '../Card/Card'

// const useStyles = makeStyles(presentationStyle)

const AttributesGrid = () => {
  const { state } = useContext(AppContext)
  const { product } = state

  const [attributesArray, setAttributesArray] = useState([])

  const attributes = []
  const values = []

  useEffect(() => {
    for (const el of product.attributes) {
      if (el.name.includes('<VN>')) {
        values.push(el)
      } else {
        attributes.push(el)
      }
    }
    setAttributesArray(attributes)
    // eslint-disable-next-line
  }, [product.attributes])

  const isSmall = useMediaQuery('(max-width: 600px)')

  return (
    <div
      style={Object.assign(
        {},
        presentationStyle.lotContainer,
        isSmall ? { marginTop: '16px' } : { background: 'white' }
      )}
    >
      {/* <Typography variant='h5' sx={{ mb: 2 }}>Attributi</Typography> */}
      <GridContainer
        style={{ width: '100%' }}
        direction='row'
        justify='center'
        alignItems='center'
        spacing={6}
      >
        <GridItem
          xs={12}
          style={isSmall ? { paddingLeft: '2px', paddingRight: '2px' } : {}}
        >
          {product.attributes.length && (
            <div style={{ display: 'flex' }} className='attributesTable'>
              <GridContainer className='singleTable'>
                {attributesArray.map((element, index) => (
                  <GridItem key={String(index)} xs={12}>
                    <Typography
                      variant='h6'
                      style={{ fontWeight: isSmall ? 'bold' : 'normal' }}
                    >
                      {element.name}
                    </Typography>
                    <Typography variant='body'>{element.value}</Typography>
                  </GridItem>
                ))}
              </GridContainer>
            </div>
          )}{' '}
          {/*  : (
            <Card style={presentationStyle.cardContainer}>
              <Box pt={4} pb={4}>
                <Typography
                  variant='body2'
                  component='div'
                  style={presentationStyle.cardDescription}
                >
                  Non ci sono allergeni da mostrare
                </Typography>
              </Box>
            </Card>
          )}  */}
        </GridItem>
      </GridContainer>
    </div>
  )
}
export default AttributesGrid
