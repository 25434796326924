/* eslint-disable */

import React from 'react'
// nodejs library that concatenates classes
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @mui/material components
// import { makeStyles } from "@mui/styles";
// @material-ui/icons

// core components
// import styles from "../../assets/jss/material-kit-pro-react/components/cardStyle.js";

// const useStyles = makeStyles(styles);

export default function Card(props) {
  const {
    className,
    children,
    plain,
    profile,
    blog,
    raised,
    background,
    pricing,
    color,
    product,
    testimonial,
    ...rest
  } = props

  return <div {...rest}>{children}</div>
}

Card.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  blog: PropTypes.bool,
  raised: PropTypes.bool,
  background: PropTypes.bool,
  pricing: PropTypes.bool,
  testimonial: PropTypes.bool,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ]),
  product: PropTypes.bool,
  children: PropTypes.node
}
