import Axios from 'axios'
import configuration from '../configuration.js'
import { alog } from '../utils/apioLog'
const TRUSTY_API = configuration.apiBaseUrl
const PUBLIC_API_KEY = configuration.publicApiKey

// get che ritorna un prodotto in base al suo uuid o gtin
export const getProduct = async (uuid) => {
  try {
    const product = await Axios.get(
      `${TRUSTY_API}/marketing/public/products/${uuid}?include=userDefinedIngredient`,
      {
        headers: {
          'x-api-key': PUBLIC_API_KEY
        }
      }
    )

    alog('new product => ', product.data.data, 'DEBUG', 'info')
    return product.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati del prodotto')
  }
}

export const getAzureTranslation = async (body, params) => {
  try {
    const response = await Axios.post(
      `${TRUSTY_API}/core/translatorService/translate`,
      body,
      {
        params
      }
    )
    console.log(JSON.stringify(response.data, null, 4))
    return response.data.data
  } catch (err) {
    throw new Error('Unable to get translation')
  }
}

export const getIngredients = async () => {
  // console.log('dentro returnSelfACL')
  const response = await Axios.get(
    `${TRUSTY_API}/marketing/public/schema/new_ingredients`,
    {
      headers: {
        'x-api-key': PUBLIC_API_KEY
      }
    }
  )
  return response.data.data
}

// get che ritorna la risorsa discover
export const getDiscovers = async (params) => {
  try {
    const discover = await Axios.get(`${TRUSTY_API}/public/discovers`, {
      headers: {
        'x-api-key': PUBLIC_API_KEY
      },
      params
    })

    alog('new discover => ', discover.data.data, 'DEBUG', 'info')
    return discover.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati discover')
  }
}

// get che ritorna un'azienda in base al companyId
export const getCompany = async (uuid) => {
  try {
    const company = await Axios.get(
      `${TRUSTY_API}/marketing/public/companies/${uuid}`,
      {
        headers: {
          'x-api-key': PUBLIC_API_KEY
        }
      }
    )

    return company.data.data
  } catch (err) {
    throw new Error("Impossibile scaricare i dati dell' azienda")
  }
}

export const getProject = async (uuid) => {
  try {
    const project = await Axios.get(
      `${TRUSTY_API}/marketing/public/projects/${uuid}`,
      {
        headers: {
          'x-api-key': PUBLIC_API_KEY
        }
      }
    )

    return project.data.data
  } catch (err) {
    throw new Error("Impossibile scaricare i dati dell' azienda")
  }
}

export const getFacilities = async (uuid, params = {}) => {
  try {
    const facilities = await Axios.get(
      `${TRUSTY_API}/marketing/public/projects/${uuid}/facilities`,
      {
        headers: {
          'x-api-key': PUBLIC_API_KEY
        },
        params
      }
    )

    return facilities.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati degli stabilimenti')
  }
}
// NOTE: Funzione che ritorna tutti gli stabilimenti nella catena track and trace
export const getFacilitiesTraceability = async (uuid, params = {}) => {
  try {
    const facilities = await Axios.get(
      `${TRUSTY_API}/traceability/public/projects/${uuid}/facilities`,
      {
        headers: {
          authorization: `Basic ${configuration.token}`
        },
        params
      }
    )

    return facilities.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati degli stabilimenti')
  }
}

// NOTE: Funzione che ritorna tutti i prodotti nella catena track and trace
export const getProductsTraceability = async (uuid, params = {}) => {
  try {
    const facilities = await Axios.get(
      `${TRUSTY_API}/traceability/public/projects/${uuid}/products`,
      {
        headers: {
          authorization: `Basic ${configuration.token}`
        },
        params
      }
    )

    return facilities.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati dei prodotti')
  }
}

// Funzione che ritorna il lotto in base all' uuid del lotto o al lotNumber
export const getLots = async (productUuid, lotUuid) => {
  const lots = await Axios.get(
    `${TRUSTY_API}/marketing/public/products/${productUuid}/light-lots/${lotUuid}`,
    {
      headers: {
        'x-api-key': PUBLIC_API_KEY
      }
    }
  )
  alog('lots.data: ', lots.data, 'DEBUG', 'info')
  return lots.data.data
}

// Funzione che ritorna il lotto in base all' uuid del lotto o al lotNumber
export const getLightLots = async (productUuid, lotUuid) => {
  const lots = await Axios.get(
    `${TRUSTY_API}/marketing/public/products/${productUuid}/lots/${lotUuid}`,
    {
      headers: {
        'x-api-key': PUBLIC_API_KEY
      }
    }
  )
  alog('lots.data: ', lots.data, 'DEBUG', 'info')
  return lots.data.data
}

// Funzione che ritorna il lotto in base all' uuid del lotto o al lotNumber
export const getTraceabilityEvents = async (productGtin, lotNumber) => {
  const lots = await Axios.get(
    `${TRUSTY_API}/traceability/public/traceBackward/lot/${productGtin}/${lotNumber}`
  )
  alog('lots.data: ', lots.data, 'DEBUG', 'info')
  return lots.data.data
}

// Funzione che ritorna il lotto in base all' uuid del lotto o al lotNumber
export const getLotsRawMaterial = async (productUuid, lotUuid) => {
  try {
    const lots = await Axios.get(
      `${TRUSTY_API}/public/products/${productUuid}/lots/${encodeURIComponent(lotUuid)}`,
      {
        headers: {
          authorization: `Basic ${configuration.token}`
        }
      }
    )
    alog('lots.data: ', lots.data, 'DEBUG', 'info')
    return lots.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati del lotto')
  }
}
// Funzione che ritorna il lotto in base all' uuid del lotto o al lotNumber
export const someLots = async (productUuid, params) => {
  try {
    const lots = await Axios.get(
      `${TRUSTY_API}/marketing/public/products/${productUuid}/lots`,
      {
        headers: {
          'x-api-key': PUBLIC_API_KEY
        },
        params
      }
    )
    return lots.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati del lotto')
  }
}

// Funzione che prende un prodotto tramite il suo uuid
const getProdById = async (id) => {
  const response = await Axios.get(
    `${TRUSTY_API}/marketing/public/products/${id}`,
    {
      params: {
        // filter: JSON.stringify({ status: { $ne: 'deleted' } })
        filter: JSON.stringify({ $or: [{ status: 'deleted' }] })
      },
      headers: {
        'x-api-key': PUBLIC_API_KEY
      }
    }
  )

  return response && response.data && response.data.data
    ? response.data.data
    : []
}

// Funzione che prende le informazioni mancanti riguardanti facilities e prodotti e le inseriscono nel lotto
export const populateLotData = async (lots) => {
  if (Array.isArray(lots.traceability)) {
    for (const index in lots.traceability) {
      const track = lots.traceability[index]
      // prprietà aggiunta per la visualizzazione delle card nella scheda tracciabilità
      track.activeSection = { card: index, showMap: false }

      if (Array.isArray(track.inputs)) {
        for (const input of track.inputs) {
          input.inputData = await getProdById(input.productId)
        }
      }

      if (Array.isArray(track.outputs)) {
        for (const output of track.outputs) {
          output.outputData = await getProdById(output.productId)
        }
      }
    }
  }
  return lots
}

// Funzione che prende le informazioni mancanti riguardanti facilities e prodotti e le inseriscono nel lotto
export const populateProductData = async (product) => {
  // FIXME: viene fatta una chiamata per ogni input. Quindi vengono fatte più richieste verso la stessa risorsa quando gli input sono uguali.
  if (Array.isArray(product.traceability)) {
    for (const index in product.traceability) {
      const track = product.traceability[index]
      // prprietà aggiunta per la visualizzazione delle card nella scheda tracciabilità
      track.activeSection = { card: index, showMap: false }

      if (Array.isArray(track.inputs)) {
        for (const input of track.inputs) {
          input.inputData = await getProdById(input.productId)
        }
      }

      if (Array.isArray(track.outputs)) {
        for (const output of track.outputs) {
          output.outputData = await getProdById(output.productId)
        }
      }
    }
  }
  return product
}

// funzione che prende le informazioni relative alle operazioni di opentimestamps, partendo dal parametro 'filename' che corrisponde all'url del file ots del lotto esaminato
export const getOpentimestampsInfo = async (filename) => {
  try {
    const opentimestampsResponse = await Axios.get(
      `${configuration.apiBaseUrl}/public/opentimestamps/info?filename=${filename}`,
      {
        headers: {
          authorization: `Basic ${configuration.token}`
        }
      }
    )

    return opentimestampsResponse.data.data
  } catch (err) {
    alog('Error: ', err, 'ERROR', 'error')
  }
}

export const sendRequestBuyNow = async (
  name,
  lastname,
  email,
  companyId,
  productId
) => {
  try {
    const response = await Axios.post(
      `${configuration.apiBaseUrl}/contacts/consumers`,
      {
        name,
        lastname,
        email,
        companyId,
        productId
      },
      {
        headers: {
          contentType: 'application/json'
        }
      }
    )
    alog('Response status:', response, 'DEBUG', 'info')
    return response.status
  } catch (error) {
    alog('Error: ', error, 'ERROR', 'error')
    return error.response.status
  }
}
