import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'

// @mui/material components
// import { makeStyles } from "@mui/styles";
import Grid from '@mui/material/Grid'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'

// const styles = {
//   grid: {
//     marginRight: "-15px",
//     marginLeft: "-15px",
//     width: "auto"
//   }
// };

// const useStyles = makeStyles(styles);

export default function GridContainer(props) {
  const { children, className, ...rest } = props
  // const classes = useStyles();
  return (
    <Grid
      container
      {...rest}
      className={className}
      style={presentationStyle.grid}
    >
      {children}
    </Grid>
  )
}

GridContainer.defaultProps = {
  className: ''
}

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
