/* eslint-disable */
/* eslint-disable react/display-name */
import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'

// @mui/material components
// import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'

import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'

// import styles from '../../assets/jss/material-kit-pro-react/components/buttonStyle.js'

// const useStyles = makeStyles(styles)

const RegularButton = React.forwardRef((props, ref) => {
  const {
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    fileButton,
    className,
    ...rest
  } = props
  // const classes = useStyles()
  const btnClasses = classNames({
    [presentationStyle.button]: true,
    [presentationStyle.round]: round,
    [presentationStyle.fullWidth]: fullWidth,
    [presentationStyle.disabled]: disabled,
    [presentationStyle.simple]: simple,
    [presentationStyle.block]: block,
    [presentationStyle.link]: link,
    [presentationStyle.justIcon]: justIcon,
    [presentationStyle.fileButton]: fileButton,
    [className]: className
  })
  return (
    <Button {...rest} ref={ref} className={btnClasses}>
      {children}
    </Button>
  )
})

RegularButton.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'twitter',
    'facebook',
    'google',
    'linkedin',
    'pinterest',
    'youtube',
    'tumblr',
    'github',
    'behance',
    'dribbble',
    'reddit',
    'instagram',
    'transparent',
    'ctaButton',
    'generalButton',
    'traceabilityButton',
    'productButton',
    'companyButton',
    'allergenesButton',
    'traceabilityButton'
  ]),
  size: PropTypes.oneOf(['sm', 'lg']),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  fileButton: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
}

export default RegularButton
