import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @mui/material components
// import { makeStyles } from "@mui/styles";
// @material-ui/icons

// core components
// import styles from "../../assets/jss/material-kit-pro-react/components/cardBodyStyle.js";

import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'

// const useStyles = makeStyles(styles);

export default function CardBody(props) {
  const {
    className,
    children,
    background,
    plain,
    formHorizontal,
    pricing,
    signup,
    color,
    ...rest
  } = props
  // const classes = useStyles();
  const cardBodyClasses = classNames({
    [presentationStyle.cardBody]: true,
    [presentationStyle.cardBodyBackground]: background,
    [presentationStyle.cardBodyBackground]: plain,
    [presentationStyle.cardBodyFormHorizontal]: formHorizontal,
    [presentationStyle.cardPricing]: pricing,
    [presentationStyle.cardSignup]: signup,
    [presentationStyle.cardBodyColor]: color,
    [className]: className !== undefined
  })
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  )
}

CardBody.propTypes = {
  className: PropTypes.string,
  background: PropTypes.bool,
  plain: PropTypes.bool,
  formHorizontal: PropTypes.bool,
  pricing: PropTypes.bool,
  signup: PropTypes.bool,
  color: PropTypes.bool,
  children: PropTypes.node
}
