/* eslint-disable react/prop-types */
import React from 'react'
// nodejs library that concatenates classes
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @mui/material components
// import { makeStyles } from '@mui/styles'

// core components
// import styles from '../../assets/jss/material-kit-pro-react/components/parallaxStyle.js'

import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle'

// const useStyles = makeStyles(styles)

export default function Parallax(props) {
  let windowScrollTop
  if (window.innerWidth >= 768) {
    windowScrollTop = window.pageYOffset / 3
  } else {
    windowScrollTop = 0
  }
  const [transform, setTransform] = React.useState(
    'translate3d(0,' + windowScrollTop + 'px,0)'
  )
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener('scroll', resetTransform)
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener('scroll', resetTransform)
      }
    }
  })
  const resetTransform = () => {
    const windowScrollTop = window.pageYOffset / 3
    setTransform('translate3d(0,' + windowScrollTop + 'px,0)')
  }
  const { className, children, style, image } = props
  // const classes = useStyles()
  return (
    <div
      className={className}
      style={Object.assign(
        {},
        presentationStyle.parallax,
        presentationStyle.small,
        {
          ...style,
          backgroundImage: 'url(' + image + ')',
          transform
        }
      )}
    >
      {children}
    </div>
  )
}

Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.oneOf([
    'primary',
    'rose',
    'dark',
    'info',
    'success',
    'warning',
    'danger'
  ]),
  children: PropTypes.node,
  image: PropTypes.string,
  small: PropTypes.bool
}
