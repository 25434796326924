import React, { useContext } from 'react'
import { AppContext } from '../../context/AppState'
import useMediaQuery from '@mui/material/useMediaQuery'
import presentationStyle from '../../assets/jss/material-kit-pro-react/views/presentationStyle.js'

const CompanyLogo = () => {
  const { state } = useContext(AppContext)
  const { company } = state
  const isSmall = useMediaQuery('(max-width: 600px)')
  return (
    <div
      style={{
        position: isSmall ? 'fixed' : 'absolute',
        top: 5,
        left: isSmall ? '20px' : '60px',
        zIndex: '999'
      }}
    >
      {company && company.logo && company.logo.original ? (
        company.website ? (
          <a
            href={`${company.website}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            <div style={Object.assign({}, presentationStyle.roundedAvatar)}>
              <img
                src={`${company.logo.original}`}
                style={{ width: '100%', height: '100%', borderRadius: '5%' }}
                alt={`${company.name} logo`}
              />
            </div>
          </a>
        ) : (
          <div style={Object.assign({}, presentationStyle.roundedAvatar)}>
            <img
              src={`${company.logo.original}`}
              style={{ width: '100%', height: '100%', borderRadius: '5%' }}
              alt={`${company.name} logo`}
            />
          </div>
        )
      ) : null}
    </div>
  )
}

export default CompanyLogo
